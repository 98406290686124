import React, { FunctionComponent } from 'react'

import Layout from '../components/core/Layout'

const styleGuide: FunctionComponent<any> = (props) => {
  return (
    <Layout>
      <main>
        <div className='pux-container'>
          <div style={{ marginBottom: `-60px` }}>
            Size 45px | Line Height 55px | Color #334055 | Margin Top 60px |
            Margin Bottom 45px
          </div>
          <h1>H1 Ubuntu Medium</h1>

          <div style={{ marginBottom: `-60px` }}>
            Size 40px | Line Height 45px | Color #334055 | Margin Top 60px |
            Margin Bottom 45px
          </div>
          <h2>H2 Ubuntu Medium</h2>

          <div style={{ marginBottom: `-40px` }}>
            Size 30px | Line Height 35px | Color #334055 | Margin Top 40px |
            Margin Bottom 40px
          </div>
          <h3>H3 Ubuntu Medium</h3>

          <div style={{ marginBottom: `-40px` }}>
            Size 24px | Line Height 30px | Color #334055 | Margin Top 40px |
            Margin Bottom 30px
          </div>
          <h4>H4 Ubuntu Medium</h4>

          <div style={{ marginBottom: `-30px` }}>
            Size 19px | Line Height 25px | Color #334055 | Margin Top 30px |
            Margin Bottom 30px
          </div>
          <h5>H5 Ubuntu Medium</h5>

          <div style={{ marginBottom: `-30px` }}>
            Size 20px | Line Height 30px | Color #334055
          </div>
          <div style={{ marginBottom: `50px` }} className='perex'>
            Cras lacinia, urna in tincidunt bibendum, quam dui suscipit massa,
            ac consequat metus massa id nibh. Nulla tempus laoreet sapien a
            interdum. Ut et consectetur urna. Cras lacinia, urna in tincidunt
            bibendum, quam dui suscipit massa, ac consequat metus massa id nibh.
            Nulla tempus laoreet sapien a interdum. Ut et consectetur urna.
          </div>

          <div>Size 16px | Line Height 25px | Color #334055</div>
          <div style={{ marginBottom: `50px` }} className='text'>
            Cras lacinia, urna in tincidunt bibendum, quam dui suscipit massa,
            ac consequat metus massa id nibh. Nulla tempus laoreet sapien a
            interdum. Ut et consectetur urna. Cras lacinia, urna in tincidunt
            bibendum, quam dui suscipit massa, ac consequat metus massa id nibh.
            Nulla tempus laoreet sapien a interdum. Ut et consectetur urna.
          </div>

          <div style={{ marginBottom: `50px` }} className='pux-wysiwyg'>
            <ul>
              <li>Duis varius, dui eu bibendum lacinia.</li>
              <li>
                Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus
                orci luctus et ultrices posuere cubilia curae; In ac auctur
                felis.
              </li>
              <li>
                Quisque id auctor nulla, sed lobortis ipsum. Pellentesque erat
                orci, finibus nec odio vel, malesuada euismod turpis. Sed id sem
                lorem.
              </li>
              <li>
                Quisque consequat leo at mattis placerat. Pellentesque tincidunt
                magna nulla, non pellentesque mi viverra ac.
              </li>
            </ul>
          </div>

          <div style={{ marginBottom: `50px` }} className='pux-wysiwyg'>
            <ol>
              <li>Duis varius, dui eu bibendum lacinia.</li>
              <li>
                Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus
                orci luctus et ultrices posuere cubilia curae; In ac auctur
                felis.
              </li>
              <li>
                Quisque id auctor nulla, sed lobortis ipsum. Pellentesque erat
                orci, finibus nec odio vel, malesuada euismod turpis. Sed id sem
                lorem.
              </li>
              <li>
                Quisque consequat leo at mattis placerat. Pellentesque tincidunt
                magna nulla, non pellentesque mi viverra ac.
              </li>
            </ol>
          </div>
          <div style={{ marginBottom: `50px` }} className='pux-wysiwyg'>
            <table>
              <tbody>
                <tr>
                  <th>Table link</th>
                  <th>Table content</th>
                  <th>Table content</th>
                  <th>Table content</th>
                </tr>
                <tr>
                  <td>
                    <a>199179001</a>
                  </td>
                  <td>15</td>
                  <td>15</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>
                    <a>199179002</a>
                  </td>
                  <td>20</td>
                  <td>20</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>
                    <a>199179003</a>
                  </td>
                  <td>25</td>
                  <td>25</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>
                    <a>199179004</a>
                  </td>
                  <td>30</td>
                  <td>30</td>
                  <td>30</td>
                </tr>
                <tr>
                  <td>
                    <a>199179005</a>
                  </td>
                  <td>35</td>
                  <td>35</td>
                  <td>35</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginBottom: `50px` }}>
            <a
              href='#'
              className='pux-button pux-button-filled pux-button-primary'
            >
              Primary Button
            </a>
          </div>
          <div style={{ marginBottom: `50px` }}>
            <a
              href='#'
              className='pux-button pux-button-outlined pux-button-primary'
            >
              Secondary Button
            </a>
          </div>
          <div style={{ marginBottom: `50px` }}>
            <a
              href='#'
              className='pux-button pux-button-filled pux-button-primary pux-button-disabled'
            >
              Primary Button Disabled
            </a>
          </div>
          <div style={{ marginBottom: `50px` }}>
            <a
              href='#'
              className='pux-button pux-button-outlined pux-button-primary pux-button-disabled'
            >
              Secondary Button Disabled
            </a>
          </div>
          <div style={{ marginBottom: `50px` }}>
            <a
              href='#'
              className='pux-button pux-button-outlined pux-button-primary pux-button-disabled'
            >
              Secondary Button Disabled
            </a>
          </div>
          <div style={{ marginBottom: `50px` }}>
            <label>Text field</label>
            <input type='text' />
          </div>
          <div style={{ marginBottom: `50px` }} className='dark'>
            <label>Text field</label>
            <input type='text' />
          </div>
          <div style={{ marginBottom: `50px` }} className='error'>
            <label>Text field</label>
            <input type='text' />
          </div>
          <div style={{ marginBottom: `50px` }}>
            <label>Textarea</label>
            <textarea />
          </div>
          <div style={{ marginBottom: `50px` }} className='dark'>
            <label>Textarea</label>
            <textarea />
          </div>
          <div style={{ marginBottom: `50px` }} className='error'>
            <label>Textarea</label>
            <textarea />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default styleGuide
